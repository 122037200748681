/* header and navbar */
header {
  background-color: #FFFFFF;
  width: 100%;
}

.navbar {
  margin-bottom: 0;
  min-height: 70px;
}

.navbar-brand {
  padding: 15px 15px 15px 0;
}

.navbar-brand img {
  max-width: 230px;
}

.navbar-nav > li > a {
  padding-top: 20px;
  color: #2f4052;
}

.nav > li > a:focus, .nav > li > a:hover {
  background-color: transparent;
}

.navbar-toggle {
  background-color: #2f4052;
  margin-right: 0;
}

.navbar-toggle .icon-bar {
  background-color: white;
}

/*cookie-alert*/
section.cookie-alert {
  background-color: #e2e0f1;
}

section.cookie-alert .alert {
  margin-bottom: 0;
  margin-top: 10px;
}

section.cookie-alert .alert-success {
  color: #2f4052;
  background-color: #e2e0f1;
  border-color: #e2e0f1;
}

section.cookie-alert a {
  display: block;
  margin: 10px 0px;
  color: #2f4052;
}

section.cookie-alert a.close {
  margin: 0;
  opacity: .7;
}

/* body, main and basic styles */
body {
  color: #666;
  font: 16px "Lato", sans-serif;
  line-height: 23px;
  word-wrap: break-word;
  font-weight: 300;
}

a {
  color: #2f4052;
}

a:hover, a:active {
  color: #a1d1f4;
}

h1, h2, h3, h4, h5, h6 {
  color: #2f4052;
}

ul {
  list-style: none;
  -webkit-padding-start: 0;
}

p {
  margin: 0 0 20px;
}

input[type="text"], input[type="email"], input[type="search"], input[type="password"], input[type="tel"], input[type="url"], input[type="date"], textarea {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  margin: 0 0 20px;
  background-color: #f9f9f9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

input[type="reset"], input[type="button"], input[type="submit"] {
  background-color: #9993c2;
  border: 1px solid #9993c2;
  color: #FFF;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  height: 40px;
  padding: 0 20px;
  margin-top: 5px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.label {
  color: #666;
  display: block;
  padding: .3em .6em .4em .2em;
  text-align: left;
}

main {
  padding: 30px 0;
}

/* blog posts */
.post {
  padding: 20px 20px 30px;
  margin-bottom: 20px;
  border: 1px solid #eaeaea;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}

.post img {
  max-width: 100%;
  margin-bottom: 10px;
}

.meta {
  padding-bottom: 0;
  margin-bottom: 20px;
  font-size: 13px;
  text-transform: uppercase;
}

.author, .date {
  padding: 0 10px 0px 5px;
}

p.title {
  font-size: 26px;
  line-height: 37px;
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #2f4052;
}

p strong {
  color: #9993c2;
  text-transform: uppercase;
}

.post-excerpt {
  padding: 20px 20px 30px;
  margin-bottom: 20px;
  border: 1px solid #eaeaea;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}

.post-excerpt img {
  max-width: 100%;
  margin-bottom: 10px;
}

.post-excerpt a.button {
  font-weight: bold;
  background-color: #a1d1f4;
  border-bottom: 1px solid #a1d1f4;
  color: #FFF;
  padding: 10px 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.pagination {
  display: block;
  min-height: 40px;
}

.pagination a {
  font-weight: bold;
  background-color: #9993c2;
  border-bottom: 1px solid #9993c2;
  color: #FFF;
  padding: 10px 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.pagination a.right {
  display: inline-block;
  float: right;
}

.pagination a.left {
  display: inline-block;
  float: left;
}

.pagination a:hover {
  text-decoration: none;
  font-weight: bold;
}

/* sidebar */
aside h3.widget-title, aside h3 {
  font-size: 16px;
  line-height: 23px;
  padding-bottom: 15px;
  margin-bottom: 20px;
  font-weight: normal;
  margin-top: 0;
  color: #333;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-bottom: 1px solid #eaeaea;
}

/* footer */
footer {
  background-color: #2f4052;
  padding: 20px 0px;
  color: #FFF;
  font-size: 11px;
  line-height: 21px;
}

footer .container {
  margin-left: auto;
  max-width: 100%;
}

footer a {
  display: none;
}

footer .copyright {
  padding-right: 10px;
}

.back-to-top {
  position: fixed;
  bottom: 10px;
  right: 20px;
}

.back-to-top a {
  display: block;
  background-color: #9993c2;
  color: #FFF;
  width: 40px;
  height: 40px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
}

.back-to-top a i {
  font-size: 30px;
  line-height: 36px;
}

@media screen and (max-width: 830px) {
  footer .copyright {
    padding-bottom: 10px;
    display: block;
  }
}

/*section specific code */
/*styles for page-header partial*/
section.page-title-wrap {
  background-color: #e2e0f1 !important;
  padding: 20px 0 20px;
  text-align: center;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  margin-top: -1px;
}

section.page-title-wrap h1.page-title {
  font-size: 26px;
  line-height: 34px;
  color: #2f4052;
  font-weight: 700;
  margin: 0 auto;
  text-transform: uppercase;
}

section.page-title-wrap .page-title:after {
  content: " ";
  display: block;
  height: 2px;
  margin: 5px auto 7px;
  width: 10%;
  background-color: #a1d1f4;
}

/* css for _partial.featured-carousel - only needed when used on a site the index page */
.carousel-control.left, .carousel-control.right {
  background-image: none !important;
}

.carousel-indicators {
  bottom: 0;
}

.carousel {
  text-align: center;
  padding: 0;
  min-height: 400px;
  background-color: #2f4052;
}

.carousel .slide_1, .carousel .slide_2, .carousel .slide_3 {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.carousel .slide_1 {
  background-image: url(/images/slider1.jpg);
}

.carousel .slide_2 {
  background-image: url(/images/slider2.jpg);
}

.carousel .slide_3 {
  background-image: url(/images/slider3.jpg);
}

.carousel .slide_message {
  text-align: center;
  margin: auto;
  padding: 4%;
  background: transparent;
}

.carousel .slide_message h1 {
  font-size: 3em;
  line-height: 1.5em;
  font-weight: bold;
  padding-bottom: 10px;
  color: #FFFFFF;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6);
}

.carousel .slide_message p {
  color: #FFFFFF;
  font-size: 20px;
  line-height: 27px;
  padding-bottom: 40px;
  width: 100%;
  text-align: center;
  margin: auto;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6);
}

.carousel .slide_message a.read-more {
  background-color: #a1d1f4;
  border: 1px solid #a1d1f4;
  color: white;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 25px;
  display: inline-block;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

/*home search only needed when including the search-jobs partial*/
section#home-search {
  text-align: center;
  background-color: #9993c2;
  padding: 20px 0 0;
  margin: -5px auto;
}

section#home-search .column {
  max-width: 1170px;
  text-align: center;
  margin: auto;
  padding-bottom: 40px;
}

section#home-search input[type="text"] {
  width: 38%;
  margin: 0 10px 20px 0;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

section#home-search input[type="submit"] {
  display: inline-block;
  height: 44px;
  width: 20%;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background-color: #a1d1f4;
  border-color: #a1d1f4;
}

section#home-search h3 {
  color: white;
  text-transform: uppercase;
  width: auto;
  text-align: center;
  margin: 20px auto 40px;
}

section#home-search .search-title:after {
  content: " ";
  display: block;
  height: 2px;
  margin: 5px auto 7px;
  width: 28%;
  background-color: #a1d1f4;
}

@media screen and (max-width: 660px) {
  section#home-search input[type="text"] {
    width: 90%;
    margin: 0 0 20px 0;
  }
  section#home-search input[type="submit"] {
    width: 90%;
    display: block;
  }
}

/*by-sector, sectors only needed when including sector-module partial in sidebar
by-location, locations only needed when including city-module partial in sidebar*/
aside .by-sector h3, aside .by-location h3 {
  font-size: 16px;
  line-height: 23px;
  padding-bottom: 15px;
  font-weight: normal;
  margin-top: 0;
  color: #333;
  letter-spacing: 2px;
  text-transform: uppercase;
}

aside ul.sectors li, aside ul.locations li {
  flex-basis: 100%;
  padding: 5px 0;
}

aside ul.sectors li i, aside ul.locations li i {
  color: #9993c2;
  width: 28px;
  text-align: center;
}

/* Home Posts Site Specific */
section#home-posts {
  text-align: center;
  padding: 0 0 20px;
}

section#home-posts .row {
  padding-bottom: 40px;
}

section#home-posts h1 {
  color: #2f4052;
  font-size: 26px;
  line-height: 36px;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  margin-bottom: 40px;
}

section#home-posts .title:after {
  background-color: #a1d1f4;
  content: " ";
  display: block;
  height: 2px;
  margin: 5px auto 7px;
  width: 28%;
}

section#home-posts .post-excerpt-home a.button {
  color: white;
  background-color: #9993c2;
  padding: 10px;
  margin-bottom: 15px;
  display: block;
  width: 100%;
  text-align: center;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

section#home-posts .post-excerpt-home .wrapper {
  background: white;
  padding: 10px 10px 20px;
  text-align: center;
  margin-bottom: 0;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}

section#home-posts .post-excerpt-home .wrapper img {
  width: 100%;
  margin-bottom: 10px;
}

section#home-posts .post-excerpt-home .wrapper p.title {
  font-size: 22px;
  line-height: 30px;
  height: auto;
  padding-bottom: 0;
  margin-bottom: 10px;
  border-bottom: 0;
  text-transform: uppercase;
}

section#home-posts .post-excerpt-home .wrapper p {
  height: 120px;
  overflow: hidden;
  margin-bottom: 10px;
}

/* site specific before_footer*/
.before-footer {
  background-color: #a1d1f4;
  color: #FFF;
  padding: 30px 0;
}

.before-footer img {
  max-width: 200px;
  padding: 10px 0;
}

.before-footer a {
  color: #FFF;
  font-weight: 400;
  font-size: 14px;
  display: block;
  padding: 5px;
}
